import React, { Component, useCallback, useEffect, useRef, useState } from 'react'
import {
  FormControl,
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Form,
  InputGroup,
  Button,
  Pagination,
  Modal
} from 'react-bootstrap'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'

// import './terminals.sass'
import { Link } from 'react-router-dom'
import { utils, writeFile } from 'xlsx'
import { TerminalForm } from '../components/terminalForm'
import { TerminalListItem } from '../components/terminalListItem'
import { PassportList } from '../../Biometric/PassportList'

function PassportPage() {



const [terminalList, setTerminalList] = useState<any>([]);


const dateFormatter = (dateTime:string)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime)
  return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
}


const getTerminalList = () => {

      fetch(`${BASE.api.base_url}${BASE.api.get_passports}/active`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': BASE.api.api_key
        },

      })
        .then((res) => res.json())
        .then((response) => {
          setTerminalList(response)
        })
        .catch((error) => {
          console.log(error)
        })
    
  }


  const downloadReports = () => {
    const reportdata = terminalList.map((item:any) => {
      return {
        nome: `${item.firstName} ${item.lastName}`,
        passaporte: item.document,
        data_captura: dateFormatter(item.biometryData),
        status: 'pendente',
        onboarding:  `https://jogodasestrelas.bepass.com.br/?token=${item.tokem}`
    }
    })

    const worksheet = utils.json_to_sheet(reportdata);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'tickets');
    writeFile(workbook, `relatorio-passaportes.xlsx`);
  }
useEffect(() => {
  getTerminalList()
},[])

  return (
    <>

<div className="game-tickets-header">
        <Row>
          <Col xl={4}>
            {/* <Card.Title as="h5">Selecione um jogo:</Card.Title> */}

            {/* <Form.Group controlId="exampleForm.ControlSelect1">
              <select
                className="form-control"
                onChange={(event) => { console.log(event.target.value) }}
              >
              <option value={`approved`}>
                Aprovados
              </option>
              <option value={`approved`}>
                Pendentes
              </option>
              <option value={`approved`}>
                Reprovados
              </option>
              </select>
            </Form.Group> */}
          {/* </Col>
          <Col md={2}> */}
            <Form.Label className="align-center">.</Form.Label>
            <input
              className="btn form-control btn-default"
              onClick={() => downloadReports()}
              value="Baixar Relatório"
            />
          </Col>
          <Col md={4}>
          <h2 className='passport-title'>
          Passaportes Pendentes: <span>{terminalList.length}</span>
          </h2>
          </Col>
        </Row>
      </div>


      <div className="game-tickets-header">
        <Table responsive striped className='terminal-list'>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Passaporte</th>
                            <th>Data da captura</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {terminalList &&
                            terminalList.map((item: any, index:any) => (
                                <PassportList terminalData={item} key={index} />
                            ))}
                    </tbody>
                </Table>
      </div>
    </>
  )
}
export default PassportPage
